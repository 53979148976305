import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Section, Title } from "../components/Core";
import StylPrivacy from "../components/Styled/StylPrivacy";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title variant="hero" className="text-center">
                  Privacy Policy
                </Title>
                <StylPrivacy>
                  <p>Last modified: August 29, 2021</p>
                  <h3 style={{ marginTop: 0 }}>
                    <strong>INTRODUCTION</strong>
                  </h3>
                  <p>
                    RewardUp Inc. and our affiliates and subsidiaries (
                    <strong>&ldquo;Company&rdquo;</strong> or
                    <strong>&nbsp;&ldquo;We&rdquo; </strong>or&ldquo;
                    <strong>Our</strong>&rdquo;) respect your privacy and are
                    committed to protecting it by complying with this policy.
                  </p>
                  <p>This policy describes:</p>
                  <ul>
                    <li>
                      How we collect, use, disclose, and protect the personal
                      information of the merchants who use our services (the
                      &ldquo;<strong>Merchants</strong>&rdquo;) and end users
                      who use and enjoy the rewards programs offered through our
                      Website and/or mobile applications (the &ldquo;
                      <strong>End Users</strong>&rdquo;) (the Merchants and the
                      End Users shall hereinafter collectively be referred to as
                      &ldquo;<strong>you</strong>&rdquo;).
                    </li>
                    <li>
                      Describes the types of information we may collect from you
                      or that you may provide when you visit the Website and/or
                      mobile applications{" "}
                      <a href="https://rewardup.com">https://rewardup.com</a>{" "}
                      (our &ldquo;
                      <strong>Website and/or mobile applications</strong>
                      &rdquo;) or when you access our services through our
                      mobile application or web application embedded by API into
                      sites, payment devices, plug-ins or other software owned
                      and managed by third parties (&ldquo;
                      <strong>Third</strong>
                      <strong>Party Website and/or mobile applications</strong>
                      &rdquo;).
                    </li>
                    <li>
                      Our practices for collecting, using, maintaining,
                      protecting, and disclosing that information.
                    </li>
                  </ul>
                  <p>
                    Privacy laws in Canada generally define &ldquo;personal
                    information&rdquo; as any information about an identifiable
                    individual, which includes information that can be used on
                    its own or with other information to identify, contact, or
                    locate a single person. Personal information does not
                    include business contact information, including your name,
                    title, or business contact information.
                  </p>
                  <p>
                    This policy applies to information we collect, use, or
                    disclose about you:
                  </p>
                  <ul>
                    <li>On this Website and/or mobile applications;</li>
                    <li>On our mobile and web applications</li>
                    <li>
                      Through Third Party Website and/or mobile applications;
                      and
                    </li>
                    <li>In email, text, and other electronic messages.</li>
                  </ul>
                  <p>
                    The Website and/or mobile applications and/or mobile
                    application may include links to Third Party Website and/or
                    mobile applications, plug-ins, services, social networks, or
                    applications. Clicking on those links or enabling those
                    connections may allow the third party to collect or share
                    data about you. If you follow a link to a Third Party
                    Website and/or mobile applications or engage a third-party
                    plugin, please note that these third parties have their own
                    privacy policies and we do not accept any responsibility or
                    liability for these policies. We do not control these Third
                    Party Website and/or mobile applications, and we encourage
                    you to read the privacy policy of every Website and/or
                    mobile applications you visit.
                  </p>
                  <p>
                    Please read this policy carefully to understand our policies
                    and practices for collecting, processing, and storing your
                    information. If you do not agree with our policies and
                    practices, your choice is not to use our Website and/or
                    mobile applications or mobile applications. By accessing or
                    using this Website and/or mobile applications and/or mobile
                    applications, you indicate that you understand, accept, and
                    consent to the practices described in this policy. This
                    policy may change from time to time. Your continued use of
                    this Website and/or mobile applications and/or mobile
                    applications after we make changes indicates that you accept
                    and consent to those changes, so please check the policy
                    periodically for updates.&nbsp;
                  </p>
                  <h3>
                    <strong>INFORMATION WE COLLECT ABOUT YOU</strong>
                  </h3>
                  <p>
                    We collect and use several types of information from and
                    about you, including:
                  </p>
                  <ul>
                    <li>
                      <strong>Personal information</strong>, that we can
                      reasonably use to directly or indirectly identify you,
                      such as your name, mailing address, e-mail address,
                      telephone number, Internet protocol (IP) address used to
                      connect your computer to the Internet, user name or other
                      similar identifier, billing and account information and
                      any other identifier we may use to contact you (&ldquo;
                      <strong>personal information</strong>&rdquo;).&nbsp;{" "}
                      <br />
                      We provide an opportunity for any user to unsubscribe from
                      our services or opt-out of contact for marketing purposes
                      on an ongoing basis by using the unsubscribe mechanism at
                      the bottom of our e-mails, or e-mailing
                      to&nbsp;support@rewardup.com.
                    </li>

                    <li>
                      <strong>Non-personal information</strong>, that does not
                      directly or indirectly reveal your identity or directly
                      relate to an identifiable individual, such as demographic
                      information or statistical or aggregated information.
                      Statistical or aggregated data does not directly identify
                      a specific person, but we may derive non-personal
                      statistical or aggregated data from personal information.
                      For example, we may aggregate personal information to
                      calculate the percentage of users accessing a specific
                      Website and/or mobile applications feature.&nbsp;
                    </li>
                    <li>
                      <strong>Technical information</strong>, including your
                      login information, browser type and version, time zone
                      setting, browser plug-in types and versions, operating
                      system and platform, or information about your internet
                      connection, the equipment you use to access our Website
                      and/or mobile applications, and usage details.
                    </li>
                    <li>
                      <strong>
                        Non-personal details about your Website and/or mobile
                        applications interactions
                      </strong>
                      , including the full Uniform Resource Locators (URLs),
                      clickstream to, through and from our Website and/or mobile
                      applications (including date and time), products you
                      viewed or searched for, page response times, download
                      errors, length of visits to certain pages, page
                      interaction information (such as scrolling, clicks, and
                      mouse-overs), methods used to browse away from the page,
                      or any phone number used to call our customer service
                      number.
                    </li>
                  </ul>
                  <h3>
                    <strong>HOW WE COLLECT INFORMATION ABOUT YOU</strong>
                  </h3>
                  <p>
                    We use different methods to collect your information,
                    including through:
                  </p>
                  <ul>
                    <li>
                      <strong>Direct interactions with you</strong>
                      <strong>when you provide it to us</strong>, for example,
                      by filling in forms or corresponding with us by phone,
                      email, or otherwise.&nbsp;
                    </li>
                    <li>
                      <strong>Automated technologies or interactions</strong>,
                      as you navigate through our Website and/or mobile
                      applications. Information collected automatically may
                      include usage details, IP addresses, and information
                      collected through cookies, web beacons, and other tracking
                      technologies.
                    </li>
                    <li>
                      <strong>
                        Third parties or publicly available sources
                      </strong>
                      , for example, our business partners or the Third Party
                      Website and/or mobile applications in which our Widget is
                      embedded.
                    </li>
                  </ul>
                  <h3>
                    <strong>Direct Collection of Information</strong>
                  </h3>
                  <p>
                    The information we collect directly from you on or through
                    our Website and/or mobile applications or Third Party
                    Website and/or mobile applications may include:
                  </p>
                  <ul>
                    <li>
                      Information that you provide by filling in forms on our
                      Website and/or mobile applications or Third Party Website
                      and/or mobile applications. This includes, but is not
                      limited to, information provided at the time of
                      registering to use our Website and/or mobile applications
                      or on Third Party Website and/or mobile applications,
                      subscribing to our service, and/or requesting further
                      services. We may also ask you for information when you
                      report a problem with our Website and/or mobile
                      applications or service.
                    </li>
                    <li>
                      Records and copies of your correspondence (including email
                      addresses and chat sessions), if you contact us.
                    </li>
                    <li>
                      Your responses to surveys that we might ask you to
                      complete for research purposes.
                    </li>
                    <li>
                      Details of transactions you carry out through our Website
                      and/or mobile applications or through Third Party Website
                      and/or mobile applications.&nbsp;
                    </li>
                  </ul>
                  <p>
                    Merchants may also provide information to be published or
                    displayed (hereinafter, &ldquo;<strong>posted</strong>
                    &rdquo;) on public areas of the Website and/or mobile
                    applications, social media, or transmitted to End Users of
                    the Website and/or mobile applications, or third parties
                    (collectively, &ldquo;<strong>Merchant Promotions</strong>
                    &rdquo;). Merchant Promotions are posted on and transmitted
                    to others at Merchants&rsquo; own risk. We do not moderate
                    the content of Merchant Promotions, so we cannot guarantee
                    the content that will be viewed by the End User. Any
                    concerns an End User may have about the content of a
                    Merchant&rsquo;s Promotion should be communicated directly
                    to the Merchant. Additionally, we cannot control the actions
                    of the End Users of the Website and/or mobile applications
                    with whom the Merchants may choose to share Merchant
                    Promotions. Therefore, we cannot and do not guarantee that
                    unauthorized persons will not view Merchant Promotions.
                  </p>
                  <h3>
                    <strong>
                      Information We Collect Through Cookies and Other Automatic
                      Data Collection Technologies{" "}
                    </strong>
                  </h3>
                  <p>
                    As you navigate through and interact with our Website and/or
                    mobile applications, we may use cookies or other automatic
                    data collection technologies to collect certain information
                    about your equipment, browsing actions, and patterns,
                    including:
                  </p>
                  <ul>
                    <li>
                      Details of your visits to our Website and/or mobile
                      applications, including traffic data, location data, logs,
                      and other communication data and the resources that you
                      access and use on the Website and/or mobile applications.
                    </li>
                    <li>
                      Information about your computer and internet connection,
                      including your IP address, operating system, and browser
                      type.
                    </li>
                  </ul>
                  <p>
                    The information we collect automatically is statistical data
                    and may include personal information, and we may maintain it
                    or associate it with personal information we collect in
                    other ways, that you provide to us, or receive from third
                    parties. It helps us to improve our Website and/or mobile
                    applications and to deliver a better and more personalized
                    service, including by enabling us to:
                  </p>
                  <ul>
                    <li>Estimate our audience size and usage patterns.</li>
                    <li>
                      Store information about your preferences, allowing us to
                      customize our Website and/or mobile applications according
                      to your individual interests.
                    </li>
                    <li>Speed up your searches.</li>
                    <li>
                      Recognize you when you return to our Website and/or mobile
                      applications.
                    </li>
                  </ul>
                  <p>
                    The technologies we use for this automatic data collection
                    may include:
                  </p>
                  <ul>
                    <li>
                      <strong>Cookies (or browser cookies).</strong> A cookie is
                      a small file placed on the hard drive of your computer.
                      You may refuse to accept browser cookies by activating the
                      appropriate setting on your browser. However, if you
                      select this setting, you may be unable to access certain
                      parts of our Website and/or mobile applications. Unless
                      you have adjusted your browser setting so that it will
                      refuse cookies, our system will issue cookies when you
                      direct your browser to our Website and/or mobile
                      applications.&nbsp;
                    </li>
                    <li>
                      <strong>Web Beacons.</strong> Pages of our Website and/or
                      mobile applications and our e-mails may contain small
                      electronic files known as web beacons (also referred to as
                      clear gifs, pixel tags, and single-pixel gifs) that permit
                      the Company, for example, to count users who have visited
                      those pages or opened an email and for other related
                      Website and/or mobile applications statistics (for
                      example, recording the popularity of certain Website
                      and/or mobile applications content and verifying system
                      and server integrity).&nbsp;
                    </li>
                  </ul>
                  <h3>
                    <strong>
                      Third Party Use of Cookies and Other Tracking Technologies
                    </strong>
                  </h3>
                  <p>
                    Some content or applications on the Website and/or mobile
                    applications, including advertisements, are served by third
                    parties, including advertisers, ad networks and servers,
                    content providers, payment systems, point of sale systems,
                    and application providers. These third parties may use
                    cookies alone or in conjunction with web beacons or other
                    tracking technologies to collect information about you when
                    you use our Website and/or mobile applications. The
                    information they collect may be associated with your
                    personal information or they may collect information,
                    including personal information, about your online activities
                    over time and across different Website and/or mobile
                    applications and other online services. They may use this
                    information to provide you with interest-based (behavioural)
                    advertising or other targeted content.&nbsp;
                  </p>
                  <p>
                    You can opt-out of several third-party ad servers' and
                    networks' cookies simultaneously by using an opt-out tool
                    created by the Digital Advertising Alliance of Canada
                    (https://youradchoices.ca/en/tools). You can also access
                    these Website and/or mobile applications to learn more about
                    online behavioural advertising and how to stop Website
                    and/or mobile applications from placing cookies on your
                    device. Opting out of a network does not mean you will no
                    longer receive online advertising. It does mean that the
                    network from which you opted out will no longer deliver ads
                    tailored to your web preferences and usage patterns.
                  </p>
                  <p>
                    We do not control these third parties' tracking technologies
                    or how they are used. If you have any questions about an
                    advertisement or other targeted content, you should contact
                    the responsible provider directly.&nbsp;
                  </p>
                  <h3>
                    <strong>HOW WE USE YOUR INFORMATION</strong>
                  </h3>
                  <p>
                    We use information that we collect about you or that you
                    provide to us, including any personal information:
                  </p>
                  <ul>
                    <li>
                      To present our Website and/or mobile applications and its
                      contents to you.
                    </li>
                    <li>
                      To provide you with information, products, or services
                      that you request from us.
                    </li>
                    <li>
                      To fulfill the purposes for which you provided the
                      information or that were described when it was collected,
                      or any other purpose for which you provide it.
                    </li>
                    <li>To provide you with notices about your account.</li>
                    <li>
                      To carry out our obligations and enforce our rights
                      arising from any contracts with you, including for billing
                      and collection or to comply with legal requirements.
                    </li>
                    <li>
                      To notify you about changes to our Website and/or mobile
                      applications or any products or services we offer or
                      provide though it.
                    </li>
                    <li>
                      To improve our Website and/or mobile applications,
                      products or services, marketing, or customer relationships
                      and experiences.
                    </li>
                    <li>
                      In any other way we may describe when you provide the
                      information.
                    </li>
                    <li>For any other purpose with your consent.</li>
                  </ul>
                  <p>
                    We may also use your information to contact you about our
                    own and third-parties' goods and services that may be of
                    interest to you, as permitted by law. If you do not want us
                    to use your information in this way, please use the
                    unsubscribe mechanism at the bottom of our e-mails.&nbsp;
                  </p>
                  <h3>
                    <strong>DISCLOSURE OF YOUR INFORMATION</strong>
                  </h3>
                  <p>
                    We may disclose aggregated information about our users, and
                    information that does not identify any individual, without
                    restriction.
                  </p>
                  <p>
                    We may disclose personal information that we collect, or you
                    provide as described in this privacy policy:
                  </p>
                  <ul>
                    <li>To our subsidiaries and affiliates.</li>
                    <li>
                      In accordance with applicable law, to a buyer or other
                      successor in the event of a merger, divestiture,
                      restructuring, reorganization, dissolution, or other sale
                      or transfer of some or all of RewardUp Inc.'s assets,
                      whether as a going concern or as part of bankruptcy,
                      liquidation, or similar proceeding, in which personal
                      information held by Rewardup Inc. about our customers and
                      users is among the assets transferred.
                    </li>
                    <li>
                      To those contractors, service providers, and other third
                      parties we use to support our business (such as analytics
                      and search engine providers that assist us with Website
                      and/or mobile applications improvement and optimization)
                      who need to know such personal information as a condition
                      to performing their services on our behalf, and who are
                      contractually obligated to keep personal information
                      confidential, use it only for the purposes for which we
                      disclose it to them, and to process the personal
                      information with the same standards set out in this
                      policy.
                    </li>
                    <li>
                      To the extent you have obtained or redeemed a reward
                      offered by a Merchant, added a Merchant as a membership to
                      your account, made a purchase at or through a Merchant, or
                      otherwise participated in any other activity sponsored or
                      offered on behalf of the Merchant, we may provide your
                      personal and transactional information to such Merchants
                      (a) for the Merchant to redeem and validate your reward,
                      send you customized offers, troubleshoot redemption,
                      combat fraud and otherwise communicate with you; (b) as
                      part of legal proceedings affecting the Company or the
                      Merchant; or (c) to send you marketing information or
                      otherwise facilitate a direct relationship between the
                      Merchant and you.
                    </li>
                    <li>
                      To any third parties for which our services are embedded
                      by API into a Third Party Website and/or mobile
                      applications (i.e., the delivery of data or usage
                      statistics on a mobile application);
                    </li>
                    <li>
                      To fulfill the purpose for which you provide it.&nbsp;
                    </li>
                    <li>
                      For any other purpose disclosed by us when you provide the
                      information.
                    </li>
                    <li>With your consent.</li>
                  </ul>
                  <p>We may also disclose your personal information:</p>
                  <ul>
                    <li>
                      To comply with any court order, law, or legal process,
                      including to respond to any government or regulatory
                      request, in accordance with applicable law.
                    </li>
                    <li>
                      To enforce or apply our terms of use
                      https://rewardup.com/terms and other agreements, including
                      for billing and collection purposes.
                    </li>
                    <li>
                      If we believe disclosure is necessary or appropriate to
                      protect the rights, property, or safety of the Company,
                      our customers, or others. This includes exchanging
                      information with other companies and organizations for the
                      purposes of fraud protection and credit risk reduction.
                    </li>
                  </ul>
                  <h3>
                    <strong>TRANSFERRING YOUR PERSONAL INFORMATION</strong>
                  </h3>
                  <p>
                    We may transfer personal information that we collect or that
                    you provide as described in this policy to contractors,
                    service providers, and other third parties we use to support
                    our business (such as analytics and search engine providers
                    that assist us with Website and/or mobile applications
                    improvement and optimization) and who are contractually
                    obligated to keep personal information confidential, use it
                    only for the purposes for which we disclose it to them, and
                    to process the personal information with the same standards
                    set out in this policy.
                  </p>
                  <p>
                    We may process, store, and transfer your personal
                    information in and to a foreign country, with different
                    privacy laws that may or may not be as comprehensive as
                    Canadian law. In these circumstances, the governments,
                    courts, law enforcement, or regulatory agencies of that
                    country may be able to obtain access to your personal
                    information through the laws of the foreign country.
                    Whenever we engage a service provider, we require that its
                    privacy and security standards adhere to this policy and
                    applicable Canadian privacy legislation.
                  </p>
                  <p>
                    By submitting your personal information or engaging with the
                    Website and/or mobile applications or Third Party Website
                    and/or mobile applications, you consent to this transfer,
                    storage, or processing.
                  </p>
                  <h3>
                    <strong>
                      CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION
                    </strong>
                  </h3>
                  <p>
                    We strive to provide you with choices regarding the personal
                    information you provide to us. We have created mechanisms to
                    provide you with the following control over your
                    information:&nbsp;
                  </p>
                  <ul>
                    <li>
                      <strong>Tracking Technologies and Advertising.</strong>{" "}
                      You can set your browser to refuse all or some browser
                      cookies, or to alert you when cookies are being sent. If
                      you disable or refuse cookies, please note that some parts
                      of this Website and/or mobile applications may not be
                      accessible or may not function properly. For more
                      information about tracking technologies, see Information
                      We Collect Through Cookies and Other Automatic Data
                      Collection Technologies .&nbsp;
                    </li>
                    <li>
                      <strong>Third-Party Advertising.</strong> If you do not
                      want us to share your personal information with
                      unaffiliated or non-agent third parties for promotional
                      purposes, you can opt-out by sending us an email stating
                      your request to support@rewardup.com.
                    </li>
                    <li>
                      <strong>Promotional Offers from the Company.</strong> If
                      you have opted in to receive certain emails from us but no
                      longer wish to have your email address/contact information
                      used by the Company to promote our own or third parties'
                      products or services, you can opt-out by sending us an
                      email stating your request to support@rewardup.com.If we
                      have sent you a promotional email, you may unsubscribe by
                      clicking the unsubscribe link we have included in the
                      email. This opt-out does not apply to information provided
                      to the Company as part of a purchase, product service
                      experience, or other transactions.
                      <br />
                      We do not control third parties' collection or use of your
                      information to serve interest-based advertising. However,
                      these third parties may provide you with ways to choose
                      not to have your information collected or used in this
                      way. You can opt out of several third-party ad servers'
                      and networks' cookies simultaneously by using an opt-out
                      tool created by the Digital Advertising Alliance of
                      Canada. You can also access these Website and/or mobile
                      applications to learn more about online behavioural
                      advertising and how to stop Website and/or mobile
                      applications from placing cookies on your device. Opting
                      out of a network does not mean you will no longer receive
                      online advertising. It does mean that the network from
                      which you opted out will no longer deliver ads tailored to
                      your web preferences and usage patterns.
                    </li>
                  </ul>

                  <h3>
                    <strong>DATA SECURITY</strong>
                  </h3>
                  <p>
                    The security of your personal information is very important
                    to us. We use technological, procedural, physical,
                    electronic, and administrative measures designed to (i)
                    ensure the confidentiality, security, integrity and
                    availability of your personal information; (ii) protect
                    against any anticipated threats or hazards to the security
                    and integrity of your personal information; and (iii)
                    protect against any accidental loss and from unauthorized
                    access, use, alteration, and disclosure.&nbsp;
                  </p>
                  <p>
                    The safety and security of your information also depends on
                    you. Where we have given you (or where you have chosen) a
                    password for access to certain parts of our Website and/or
                    mobile applications, you are responsible for keeping this
                    password confidential. We ask you not to share your password
                    with anyone.&nbsp;
                  </p>
                  <p>
                    Unfortunately, the transmission of information via the
                    Internet is not completely secure. Although we do our best
                    to protect your personal information, we cannot guarantee
                    the security of your personal information transmitted to our
                    Website and/or mobile applications. Any transmission of
                    personal information is at your own risk. We are not
                    responsible for circumvention of any privacy settings or
                    security measures contained on the Website and/or mobile
                    applications.&nbsp;
                  </p>
                  <h3>
                    <strong>DATA RETENTION</strong>
                  </h3>
                  <p>
                    Except as otherwise permitted or required by applicable law
                    or regulation, we will only retain your personal information
                    for as long as necessary to fulfill the purposes we
                    collected it for, including for the purposes of satisfying
                    any legal, accounting, or reporting requirements. Under some
                    circumstances we may anonymize your personal information so
                    that it can no longer be associated with you. We reserve the
                    right to use such anonymous and de-identified data for any
                    legitimate business purpose without further notice to you or
                    your consent.&nbsp;
                  </p>
                  <p>
                    Without limiting the foregoing, we agree that we will
                    permanently and securely delete any End Consumer data or
                    Merchant data we have stored when a Merchant uninstalls or
                    discontinues its use of our Website and/or mobile
                    applications, and such Merchant or End User will be provided
                    with a one hundred and eighty (180) days to obtain any such
                    consumer data, Merchant data, or End User data, as
                    applicable.
                  </p>
                  <h3>
                    <strong>CHILDREN UNDER THE AGE OF 13</strong>
                  </h3>
                  <p>
                    Our Website and/or mobile applications is not intended for
                    children under 13 years of age. No one under age 13 may
                    provide any information to or on the Website and/or mobile
                    applications. We do not knowingly collect personal
                    information from children under 13. If you are under 13, do
                    not use or provide any information on this Website and/or
                    mobile applications or on or through any of its
                    features/register on the Website and/or mobile applications,
                    make any purchases through the Website and/or mobile
                    applications, use any of the interactive or public comment
                    features of this Website and/or mobile applications, or
                    provide any information about yourself to us, including your
                    name, address, telephone number, email address, or any
                    screen name or user name you may use. If we learn we have
                    collected or received personal information from a child
                    under 13 without verification of parental consent, we will
                    delete that information. If you believe we might have any
                    information from or about a child under 13, please contact
                    us at support@rewardup.com.
                  </p>
                  <h3>
                    <strong>
                      ACCESSING AND CORRECTING YOUR PERSONAL INFORMATION
                    </strong>
                  </h3>
                  <p>
                    It is important that the personal information we hold about
                    you is accurate and current. Please keep us informed if your
                    personal information changes. By law you have the right to
                    request access to and to correct the personal information
                    that we hold about you.
                  </p>
                  <p>
                    You can review and change your personal information by
                    logging into the Website and/or mobile applications and
                    visiting your account profile page.
                  </p>
                  <p>
                    If you want to review, verify, correct, or withdraw consent
                    to the use of your personal information you may also send us
                    an email at{" "}
                    <a href="mailto:support@rewardup.com">
                      support@rewardup.com
                    </a>{" "}
                    to request access to, correct, or delete any personal
                    information that you have provided to us. We cannot delete
                    your personal information except by also deleting your user
                    account on our Website and/or mobile applications. We may
                    not accommodate a request to change information if we
                    believe the change would violate any law or legal
                    requirement or cause the information to be incorrect. While
                    we can delete your personal information from our records and
                    our Website and/or mobile applications, we cannot delete any
                    of your personal information that has been sent to the
                    Merchant. In order to have your personal information deleted
                    from the Merchant&rsquo;s records, please contact the
                    Merchant directly with your deletion request.
                  </p>
                  <p>
                    We may request specific information from you to help us
                    confirm your identity and your right to access, and to
                    provide you with the personal information that we hold about
                    you or make your requested changes. Applicable law may allow
                    or require us to refuse to provide you with access to some
                    or all of the personal information that we hold about you,
                    or we may have destroyed, erased, or made your personal
                    information anonymous in accordance with our record
                    retention obligations and practices. If we cannot provide
                    you with access to your personal information, we will inform
                    you of the reasons why, subject to any legal or regulatory
                    restrictions.
                  </p>
                  <p>
                    We will provide access to your personal information, subject
                    to exceptions set out in applicable privacy legislation.
                    Examples of such exceptions include:
                  </p>
                  <ul>
                    <li>
                      Information protected by solicitor-client privilege.
                    </li>
                    <li>
                      Information that is part of a formal dispute resolution
                      process.
                    </li>
                    <li>
                      Information that is about another individual that would
                      reveal their personal information or confidential
                      commercial information.
                    </li>
                    <li>
                      Information that is prohibitively expensive to provide.
                    </li>
                  </ul>
                  <h3>
                    <strong>WITHDRAWING YOUR CONSENT</strong>
                  </h3>
                  <p>
                    Where you have provided your consent to the collection, use,
                    and transfer of your personal information, you may have the
                    legal right to withdraw your consent under certain
                    circumstances. To withdraw your consent, if applicable,
                    contact us at support@rewardup.com. Please note that if you
                    withdraw your consent we may not be able to provide you with
                    a particular product or service. We will explain the impact
                    to you at the time to help you with your decision.&nbsp;
                  </p>
                  <h3>
                    <strong>CHANGES TO OUR PRIVACY POLICY</strong>
                  </h3>
                  <p>
                    It is our policy to post any changes we make to our privacy
                    policy on this page. If we make material changes to how we
                    treat our users' personal information, we will notify you by
                    email to the primary email address specified in your account
                    or through a notice on the Website and/or mobile
                    applications home page.&nbsp;
                  </p>
                  <p>
                    We include the date the privacy policy was last revised at
                    the top of the page. You are responsible for ensuring we
                    have an up-to-date, active, and deliverable email address
                    for you, and for periodically visiting our Website and/or
                    mobile applications and this privacy policy to check for any
                    changes.
                  </p>
                  <h3>
                    <strong>FOR CALIFORNIA USERS</strong>
                  </h3>
                  <p>
                    We do not sell your personal information and we comply with
                    CCPA requirements; but RewardUp may use your personnel
                    information in the following ways:
                  </p>
                  <ul>
                    <li>
                      We may use your information to update, secure,
                      troubleshoot and provide support for our products. It also
                      includes sharing data, when it is required to provide the
                      service or carry out the transactions that you request.
                    </li>
                    <li>
                      We may use your personal information to help authenticate
                      you should you ever contact us for support.
                    </li>
                    <li>
                      We may share information with companies including third
                      party integration partners that provide support services
                      to us (such as email or invoicing vendors) and that help
                      us communicate to you on behalf of program owners. These
                      companies may use information about you to perform their
                      functions on our behalf.
                    </li>
                    <li>
                      In the event of a merger, acquisition, financing, or sale
                      of assets or any other situation involving the transfer of
                      some or all of our business assets, RewardUp may disclose
                      personal information to those involved in the negotiation
                      or transfer.
                    </li>
                    <li>
                      We may contact you with special offers, promotions, and/or
                      sales inquiries.
                    </li>
                    <li>
                      We may also share aggregated or anonymized information
                      that does not directly identify you.
                    </li>
                  </ul>
                  <h3>
                    <strong>DELETION REQUESTS&nbsp;</strong>
                  </h3>
                  <p>
                    You have the right to request that we delete any of your
                    personal information that we collected from you and
                    retained, subject to certain exceptions. Once we receive and
                    confirm your verifiable consumer request, we will delete
                    (and direct our service providers to delete) your personal
                    information from our records, unless an exception
                    applies.&nbsp;
                  </p>
                  <p>
                    We may deny your deletion request if retaining the
                    information is necessary for us or our service providers
                    to:&nbsp;
                  </p>
                  <ol>
                    <li>
                      Complete the transaction for which we collected the
                      personal information, provide a good or service that you
                      requested, take actions reasonably anticipated within the
                      context of our ongoing business relationship with you, or
                      otherwise perform our contract with you.&nbsp;
                    </li>
                    <li>
                      Detect security incidents, protect against malicious,
                      deceptive, fraudulent, or illegal activity, or prosecute
                      those responsible for such activities.&nbsp;
                    </li>
                    <li>
                      Debug products to identify and repair errors that impair
                      existing intended functionality.&nbsp;
                    </li>
                    <li>
                      Exercise free speech, ensure the right of another consumer
                      to exercise their free speech rights, or exercise another
                      right provided for by law.&nbsp;
                    </li>
                    <li>
                      Comply with the California Electronic Communications
                      Privacy Act (Cal. Penal Code &sect; 1546 seq.).&nbsp;
                    </li>
                    <li>
                      Engage in public or peer-reviewed scientific, historical,
                      or statistical research in the public interest that
                      adheres to all other applicable ethics and privacy laws,
                      when the information's deletion may likely render
                      impossible or seriously impair the research's achievement,
                      if you previously provided informed consent.&nbsp;
                    </li>
                    <li>
                      Enable solely internal uses that are reasonably aligned
                      with consumer expectations based on your relationship with
                      us.&nbsp;
                    </li>
                    <li>Comply with a legal obligation.&nbsp;</li>
                    <li>
                      Make other internal and lawful uses of that information
                      that are compatible with the context in which you provided
                      it.&nbsp;
                    </li>
                  </ol>
                  <p>
                    To exercise the access, data portability, and deletion
                    rights described above, please submit a verifiable consumer
                    request.&nbsp;
                  </p>
                  <p>
                    To exercise your rights or to obtain more information, you
                    may contact us:&nbsp;
                  </p>
                  <ul>
                    <li>By email at: support@rewardup.com</li>
                    <li>By telephone at: (437) 290-5123</li>
                  </ul>
                  <h3>
                    <strong>
                      CONTACT INFORMATION AND CHALLENGING COMPLIANCE
                    </strong>
                  </h3>
                  <p>
                    We welcome your questions, comments, and requests regarding
                    this privacy policy and our privacy practices. Please
                    contact us at:&nbsp;
                  </p>
                  <p>
                    RewardUp Inc.
                    <br />
                    Attn: Privacy Officer
                    <br />1 Yonge St, #1801, Toronto, ON, M5E 1W7
                  </p>
                  <p>
                    <a href="mailto:support@rewardup.com">
                      support@rewardup.com
                    </a>
                  </p>
                  <p>
                    We have procedures in place to receive and respond to
                    complaints or inquiries about our handling of personal
                    information, our compliance with this policy, and with
                    applicable privacy laws. To discuss our compliance with this
                    policy please contact our Privacy Officer using the contact
                    information listed above.
                  </p>
                </StylPrivacy>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
